import { FirebaseError } from 'firebase/app'
import { getAuth, GoogleAuthProvider, linkWithPopup, linkWithRedirect, signInWithCredential, signInWithRedirect, User } from 'firebase/auth'

// Execute the Google Auth redirect flow
// The results of this redirect are picked up in root.tsx
export default async function googleAuthRedirect() {
  const auth = getAuth()
  const currentUser = auth.currentUser

  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    prompt: 'select_account',
  })

  try {
    if (currentUser == null) {
      // No existing user, start sign in with redirect
      console.log('Signing in with redirect...')
      signInWithRedirect(auth, provider)
    } else {
      // Existing user, link account with redirect
      console.log('Linking with redirect...')

      // Detect if we are on a preview channel based on whether the TLD is web.app
      const firebase_preview_pattern = /web\.app/

      // Use Popup only on local development or on preview channels
      if (window.location.hostname === 'localhost' || firebase_preview_pattern.test(window.location.href)) {
        localhostLinkWithPopupFlow(currentUser, provider)
      } else {
        // Redirect to dashboard to avoid coming back to the same chat id and causing a 403 error
        window.history.replaceState({}, '', '/dashboard?auth_redirect=true')

        // Wait 100ms before redirect
        await new Promise((resolve) => setTimeout(resolve, 100))

        // Used when Anonymous (signed in) users are trying to sign in with this method
        linkWithRedirect(currentUser, provider)
      }
    }
  } catch (error: any) {
    if (error instanceof FirebaseError) {
      console.error('Google auth redirect firebase error: ', error)

      // // Handle Errors here.
      // const errorCode = error.code
      // const errorMessage = error.message

      // // The email of the user's account used.
      // const email = error.customData?.email

      // TODO: Add sentry telemetry
    } else {
      // TODO: Add sentry telemetry
      console.error('Google auth redirect error', error)
    }

    return
  }
}

// LOCALHOST GOOGLE SIGN IN FLOW
// This is a special case for localhost development
// It uses a popup instead of a redirect to sign in
// It is designed to simulate the redirect flow that is picked up in root.tsx rather
// than use the normal popup flow
async function localhostLinkWithPopupFlow(user: User, provider: GoogleAuthProvider) {
  console.log('Initiating local dev popup GoogleAuthProvider flow...')
  const auth = getAuth()

  try {
    const userCredential = await linkWithPopup(user, provider)
    console.log('Local dev popup social flow success: ', userCredential)
  } catch (e: any) {
    if (e instanceof FirebaseError) {
      console.error('Local dev popup social flow FirebaseError: ', e)

      const errorCredential = GoogleAuthProvider.credentialFromError(e)
      console.log('Popup Flow Error credential: ', errorCredential)

      if (errorCredential == null) {
        console.error('Local dev popup social flow error credential is null')
        return
      }

      // Get the idToken
      const idToken = errorCredential.idToken
      if (idToken == null) {
        throw new Error('Local dev popup social flow error credential idToken is null')
      }

      // Sign in with the error credential
      await signInWithCredential(auth, errorCredential)
      return
    } else {
      console.error('Local dev popup GoogleAuthProvider flow error: ', e)
    }
  }
}
