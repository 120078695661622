import { NewChatIntroExample } from '@/chat-common/chat-v2-examples'
import { getAuth } from 'firebase/auth'
import { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

type DashboardExamples = NewChatIntroExample & {
  linkPath: string
}

const dashboardExamples: DashboardExamples[] = [
  {
    title: 'Laws & Regulations',
    description: 'Explore and interpret laws and regulations to create insights for case strategy and compliance.',
    linkPath: '/dashboard/chat/lrr_v2',
  },
  {
    title: 'Case Law',
    description: 'Create summaries and analytical insights to inform legal arguments and strategies.',
    linkPath: '/dashboard/chat/caselaw',
  },
  {
    title: 'File Analysis',
    description: 'Upload your documents to summarize data, make comparisons, check compliance, and analyze key insights.',
    linkPath: '/dashboard/chat/documentquery',
  },
  {
    title: 'Web & News',
    description: 'Analyze current trends and monitor legal actions and filings involving industry competitors or key players.',
    linkPath: '/dashboard/chat/websearch',
  },
]

export default function DashboardHome() {
  const navigate = useNavigate()
  const location = useLocation()

  // Redirect non users to LRR
  const auth = getAuth()
  const user = auth.currentUser

  /**
   * Redirect
   * Automatically redirect anonymous users to a new LRR chat.
   *
   * EXCEPT:
   * - After an linkWithCredential authentication redirects, the user is still
   *   anonymous until the login process inside auth-context is completed. Do
   *   not redirect in this case.
   *
   *   This is known by the presence of the 'auth_redirect' query parameter.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const authRedirect = searchParams.get('auth_redirect')

    if (user?.isAnonymous && !authRedirect) {
      // Redirect to LRR
      navigate('/dashboard/chat/lrr_v2')
    }
  })

  return (
    <div className={'flex flex-grow p-5'}>
      <div className={'flex flex-grow items-center justify-center '}>
        <div className="p-4 max-w-5xl">
          <h2 className="text-lg font-bold text-center mb-4">How can I help?</h2>
          <div className="flex flex-wrap justify-center gap-4">
            {dashboardExamples.map((item, index) => (
              <Link
                to={item.linkPath}
                key={index}
                className="md:w-[calc(50%-1rem)] max-w-md border p-4 rounded-lg transition-all duration-200 ease-in-out hover:bg-brand-neutral-100"
              >
                <h3 className="font-bold text-sm">{item.title}</h3>
                <p className="text-sm mt-1 text-brand-neutral-600">{item.description}</p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
